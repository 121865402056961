:root {
  // base
  --main-font-family: "Inter", sans-serif;
  --extra-font-family: "foglihtenno06", sans-serif;
  --content-width: 1920px;
  --content-fluid-width: 1440px;
  --container-offset: 70px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --container-fluid-width: calc(
    var(--content-fluid-width) + (var(--container-offset) * 2)
  );

  // colors
  --dark-color: #1e1e1e;
  --light-color: #ffffff;
  --phone-color: #e96d65;
  --primary-gradient: linear-gradient(86.45deg, #bc1cd7 0%, #ff525b 97.9%);
}
