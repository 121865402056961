.header {
  position: relative;
  top: 0;
  left: 0;
  padding-top: 30px;
  padding-bottom: 20px;

  @include main-mobile {
    padding-bottom: 30px;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    @include mobile {
      max-width: 95px;
    }
  }

  &__phone {
    margin-left: auto;
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: var(--light-color);
    opacity: 0.9;
    z-index: 1;

    @include mobile {
      width: 28px;
      height: 28px;
    }
  }

  &__image {
    position: absolute;
    top: -127px;
    right: -246px;

    @include desktop {
      right: -428px;
    }

    @include high-desktop {
      display: none;
    }
  }
}

.phone__icon {
  width: 24px;
  height: 24px;
  stroke: var(--phone-color);
  stroke-width: 2px;
  fill: var(--light-color);

  @include mobile {
    width: 28px;
    height: 28px;
  }
}
