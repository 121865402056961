:root {
  --main-font-family: "Inter", sans-serif;
  --extra-font-family: "foglihtenno06", sans-serif;
  --content-width: 1920px;
  --content-fluid-width: 1440px;
  --container-offset: 70px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --container-fluid-width: calc(
    var(--content-fluid-width) + (var(--container-offset) * 2)
  );
  --dark-color: #1e1e1e;
  --light-color: #ffffff;
  --phone-color: #e96d65;
  --primary-gradient: linear-gradient(86.45deg, #bc1cd7 0%, #ff525b 97.9%);
}

/* stylelint-disable */ /* stylelint-disable */ /* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */ /* stylelint-disable */ /* stylelint-disable value-keyword-case */
@font-face {
  font-family: "Inter";
  src: url("../fonts/../fonts/InterLight.woff2") format("woff2");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/../fonts/InterLightItalic.woff2") format("woff2");
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/../fonts/InterRegular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/../fonts/InterMedium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/../fonts/InterMediumItalic.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/../fonts/InterSemiBold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/../fonts/InterBold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "foglihtenno06";
  src: url("../fonts/../fonts/foglihtenno06.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--main-font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.site-container {
  overflow: hidden;
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}
@media (max-width: 576px) {
  .container {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media (max-width: 358px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.container-fluid {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-fluid-width);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.header {
  position: relative;
  top: 0;
  left: 0;
  padding-top: 30px;
  padding-bottom: 20px;
}
@media (max-width: 390px) {
  .header {
    padding-bottom: 30px;
  }
}
.header__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 576px) {
  .header__logo {
    max-width: 95px;
  }
}
.header__phone {
  margin-left: auto;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--light-color);
  opacity: 0.9;
  z-index: 1;
}
@media (max-width: 576px) {
  .header__phone {
    width: 28px;
    height: 28px;
  }
}
.header__image {
  position: absolute;
  top: -127px;
  right: -246px;
}
@media (max-width: 1362px) {
  .header__image {
    right: -428px;
  }
}
@media (max-width: 1220px) {
  .header__image {
    display: none;
  }
}

.phone__icon {
  width: 24px;
  height: 24px;
  stroke: var(--phone-color);
  stroke-width: 2px;
  fill: var(--light-color);
}
@media (max-width: 576px) {
  .phone__icon {
    width: 28px;
    height: 28px;
  }
}

.hero__container {
  min-height: 906px;
}
@media (max-width: 1024px) {
  .hero__container {
    min-height: 800px;
  }
}
@media (max-width: 847px) {
  .hero__container {
    min-height: 100%;
  }
}
.hero__content {
  position: relative;
}
.hero__title {
  margin: 0;
  padding-bottom: 38px;
  max-width: 710px;
  font-weight: 700;
  font-size: 62px;
  line-height: 0.8;
  color: var(--dark-color);
}
.hero__title-text {
  font-weight: 300;
  font-size: 32px;
  color: var(--dark-color);
}
@media (max-width: 847px) {
  .hero__title-text {
    font-size: 24px;
  }
}
@media (max-width: 576px) {
  .hero__title-text {
    font-size: 18px;
  }
}
@media (max-width: 847px) {
  .hero__title {
    max-width: 440px;
    font-size: 36px;
    line-height: 1;
  }
}
@media (max-width: 576px) {
  .hero__title {
    max-width: 290px;
    padding-bottom: 28px;
  }
}
.hero__key-words {
  max-width: 336px;
  margin: 0;
  padding-bottom: 40px;
  font-weight: 300;
  font-size: 22px;
  line-height: 150%;
  color: var(--dark-color);
}
@media (max-width: 1024px) {
  .hero__key-words {
    padding-bottom: 10px;
    font-size: 18px;
  }
}
@media (max-width: 576px) {
  .hero__key-words {
    font-size: 18px;
    padding: 0;
  }
}
.hero__subscribe-message {
  max-width: 434px;
  margin: 0;
  padding-bottom: 30px;
  font-weight: 700;
  font-size: 30px;
  line-height: 125%;
  color: var(--dark-color);
}
@media (max-width: 1024px) {
  .hero__subscribe-message {
    font-size: 22px;
  }
}
@media (max-width: 768px) {
  .hero__subscribe-message {
    display: none;
  }
}
.hero__left {
  margin-right: 10px;
  min-width: 0;
}
.hero__right {
  position: relative;
  flex: 1 1 0;
  min-width: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}
@media (max-width: 1691px) {
  .hero__right {
    left: 20%;
    transform: translateX(-20%);
  }
}
@media (max-width: 390px) {
  .hero__right {
    right: 0;
    transform: translateX(25%);
  }
}
.hero__team {
  max-width: 890px;
  width: 890px;
  position: absolute;
  top: 234px;
  left: -4px;
  z-index: 2;
}
@media (max-width: 1920px) {
  .hero__team {
    left: 50px;
  }
}
@media (max-width: 1691px) {
  .hero__team {
    top: 234px;
    left: -78px;
  }
}
@media (max-width: 1440px) {
  .hero__team {
    top: 234px;
  }
}
@media (max-width: 1362px) {
  .hero__team {
    max-width: 740px;
    top: 324px;
  }
}
@media (max-width: 1220px) {
  .hero__team {
    width: 650px;
    top: 378px;
    left: -90px;
  }
}
@media (max-width: 1125px) {
  .hero__team {
    display: none;
  }
}
.hero__information-content {
  max-width: 430px;
  position: absolute;
  top: 0;
  z-index: 1;
}
@media (max-width: 1024px) {
  .hero__information-content {
    top: 80px;
  }
}
@media (max-width: 576px) {
  .hero__information-content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;
    top: 0;
    left: 0;
    z-index: 1;
  }
}
.hero__target-text {
  max-width: 312px;
  margin: 0;
  padding-bottom: 26px;
  font-weight: 500;
  font-size: 22px;
  line-height: 125%;
  color: var(--light-color);
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
}
@media (max-width: 576px) {
  .hero__target-text {
    max-width: 242px;
    font-size: 16px;
    padding-bottom: 20px;
    align-self: flex-start;
    color: var(--dark-color);
    text-shadow: none;
  }
}
.hero__slide-image {
  -webkit-filter: drop-shadow(0px 0px 5px #fff);
  filter: drop-shadow(0px 0px 5px #fff);
}

.column-wrapper {
  display: flex;
}
@media (max-width: 768px) {
  .column-wrapper {
    flex-direction: column;
  }
}

.discount {
  position: relative;
  margin-bottom: 20px;
}
.discount__image {
  position: relative;
  max-width: 264px;
}
@media (max-width: 358px) {
  .discount__image {
    max-width: 230px;
  }
}
.discount__link {
  max-width: 245px;
  position: absolute;
  top: 5px;
  left: 10px;
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  color: var(--light-color);
}
@media (max-width: 1920px) {
  .discount__link {
    top: 8px;
  }
}
@media (max-width: 358px) {
  .discount__link {
    top: 6px;
    font-size: 18px;
  }
}

.btn__subscribe {
  display: inline-block;
  font-weight: 400;
  font-size: 22px;
  margin-bottom: 100px;
  padding: 10px 20px;
  line-height: 150%;
  color: #ffffff;
  border-radius: 7px;
  background: var(--primary-gradient);
}
@media (max-width: 1024px) {
  .btn__subscribe {
    margin-bottom: 20px;
  }
}
@media (max-width: 768px) {
  .btn__subscribe {
    display: none;
  }
}

.slide__image {
  max-width: 100%;
  width: 100%;
  position: absolute;
  top: -82px;
  z-index: 2;
}
@media (max-width: 1920px) {
  .slide__image {
    max-width: 1920px;
    width: 1332px;
    top: -150px;
  }
}
@media (max-width: 1691px) {
  .slide__image {
    width: 1200px;
    top: -110px;
    left: -72px;
  }
}
@media (max-width: 1440px) {
  .slide__image {
    width: 1034px;
  }
}
@media (max-width: 1362px) {
  .slide__image {
    width: 938px;
    top: -60px;
  }
}
@media (max-width: 1220px) {
  .slide__image {
    width: 780px;
    margin-bottom: 30px;
  }
}
@media (max-width: 1125px) {
  .slide__image {
    width: 670px;
  }
}
@media (max-width: 1024px) {
  .slide__image {
    width: 435px;
  }
}
@media (max-width: 847px) {
  .slide__image {
    position: relative;
    margin-bottom: 40px;
    top: -50px;
    left: -186px;
  }
}
@media (max-width: 768px) {
  .slide__image {
    margin-bottom: 20px;
    top: 5px;
    left: 0;
  }
}
@media (max-width: 576px) {
  .slide__image {
    width: 150%;
    top: 0;
    left: -42px;
  }
}
@media (max-width: 390px) {
  .slide__image {
    width: 170%;
    left: -200px;
  }
}
@media (max-width: 390px) {
  .slide__image {
    width: 170%;
    left: -200px;
  }
}
@media (max-width: 358px) {
  .slide__image {
    max-width: 160%;
    left: -200px;
  }
}

.inf__image {
  display: flex;
  width: 850px;
  max-width: 850px;
}

.grad__image {
  position: absolute;
  top: -6px;
  left: 0;
  height: 328px;
}

.bottom-wrapper {
  position: relative;
  height: 244px;
}
@media (max-width: 847px) {
  .bottom-wrapper {
    height: inherit;
  }
}
@media (max-width: 576px) {
  .bottom-wrapper {
    position: absolute;
    max-width: 100%;
    height: auto;
    left: 50%;
    width: 314px;
    bottom: -418px;
    padding: 20px;
    background: var(--light-color);
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7.68px;
    transform: translateX(-50%);
    z-index: 1;
  }
}
@media (max-width: 390px) {
  .bottom-wrapper {
    position: absolute;
    width: 300px;
    bottom: -410px;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (max-width: 358px) {
  .bottom-wrapper {
    padding: 20px 10px;
  }
}

.bottom__image-wrapper {
  position: absolute;
  top: -72px;
  left: -70px;
  overflow: hidden;
  z-index: 0;
}
@media (max-width: 1125px) {
  .bottom__image-wrapper {
    position: relative;
  }
}
@media (max-width: 1024px) {
  .bottom__image-wrapper {
    top: 0;
  }
}
@media (max-width: 576px) {
  .bottom__image-wrapper {
    display: none;
  }
}

.app {
  max-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 37px;
}
.app__link:not(:last-child) {
  margin-right: 20px;
}
@media (max-width: 576px) {
  .app__link:not(:last-child) {
    margin-right: 15px;
  }
}
@media (max-width: 576px) {
  .app {
    margin: 0;
    order: -1;
  }
}

.address {
  font-style: normal;
}
.address__text {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: var(--light-color);
}
@media (max-width: 768px) {
  .address__text {
    max-width: 360px;
  }
}
@media (max-width: 576px) {
  .address__text {
    padding-bottom: 20px;
    color: var(--dark-color);
  }
}
.address__link {
  color: var(--light-color);
}
.address__phone {
  font-weight: 700;
}

.mask {
  -webkit-mask-image: url("../img/sprite.svg#mask");
  mask-image: url("../img/sprite.svg#mask");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 70%;
  mask-size: 70%;
}
@media (max-width: 1691px) {
  .mask {
    -webkit-mask-size: 72%;
    mask-size: 72%;
  }
}

.mob__subscribe-message {
  display: none;
}
@media (max-width: 768px) {
  .mob__subscribe-message {
    display: block;
    max-width: 434px;
    margin: 0;
    padding-bottom: 22px;
    font-weight: 700;
    font-size: 22px;
    line-height: 125%;
    color: var(--dark-color);
  }
}

.btn-mob__subscribe {
  display: none;
}
@media (max-width: 768px) {
  .btn-mob__subscribe {
    display: inline-block;
    font-weight: 400;
    font-size: 22px;
    margin-bottom: 20px;
    padding: 10px 20px;
    line-height: 150%;
    color: #ffffff;
    border-radius: 7px;
    background: var(--primary-gradient);
    align-self: flex-start;
  }
}
@media (max-width: 847px) {
  .btn-mob__subscribe {
    margin-bottom: 20px;
  }
}

.bottom__bg-mobile {
  display: none;
}
@media (max-width: 576px) {
  .bottom__bg-mobile {
    position: relative;
    display: block;
    min-height: 350px;
    margin-top: 100px;
    background-image: url("../../img/mobile-bottom-bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 100%;
  }
}
@media (max-width: 390px) {
  .bottom__bg-mobile {
    margin-left: 10px;
    margin-right: 10px;
    min-height: 360px;
    margin-top: 80px;
    background-size: 100% 100%;
    background-position: center;
  }
}
@media (max-width: 358px) {
  .bottom__bg-mobile {
    min-height: 348px;
    margin-left: 5px;
    margin-right: 5px;
  }
}

.bg-mobile__image {
  display: none;
}
@media (max-width: 576px) {
  .bg-mobile__image {
    display: block;
    position: absolute;
    bottom: 254px;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (max-width: 390px) {
  .bg-mobile__image {
    max-width: 300px;
    width: 100%;
    height: 187px;
    left: 50%;
    bottom: 242px;
    margin-left: 0;
    object-fit: contain;
    transform: translate(-50%, 0);
  }
}
@media (max-width: 358px) {
  .bg-mobile__image {
    bottom: 232px;
  }
}

.hero__slider {
  width: 100%;
  overflow: initial;
}