.hero {
  &__container {
    // min-height: 906px;
    min-height: 906px;

    @include high-tablet {
      min-height: 800px;
    }

    @include tablet {
      min-height: 100%;
    }
  }

  &__content {
    position: relative;
  }

  &__title {
    margin: 0;
    padding-bottom: 38px;
    max-width: 710px;
    font-weight: 700;
    font-size: 62px;
    line-height: 0.8;
    color: var(--dark-color);

    &-text {
      font-weight: 300;
      font-size: 32px;
      color: var(--dark-color);

      @include tablet {
        font-size: 24px;
      }

      @include mobile {
        font-size: 18px;
      }
    }

    @include tablet {
      max-width: 440px;
      font-size: 36px;
      line-height: 1;
    }

    @include mobile {
      max-width: 290px;
      padding-bottom: 28px;
    }
  }

  &__key-words {
    max-width: 336px;
    margin: 0;
    padding-bottom: 40px;
    font-weight: 300;
    font-size: 22px;
    line-height: 150%;
    color: var(--dark-color);

    @include high-tablet {
      padding-bottom: 10px;
      font-size: 18px;
    }

    @include mobile {
      font-size: 18px;
      padding: 0;
    }
  }

  &__subscribe-message {
    max-width: 434px;
    margin: 0;
    padding-bottom: 30px;
    font-weight: 700;
    font-size: 30px;
    line-height: 125%;
    color: var(--dark-color);

    @include high-tablet {
      font-size: 22px;
    }

    @include small-tablet {
      display: none;
    }
  }

  &__left {
    margin-right: 10px;
    min-width: 0;
  }

  &__right {
    position: relative;
    flex: 1 1 0;
    min-width: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;

    @include before-hd {
      left: 20%;
      transform: translateX(-20%);
    }

    @include main-mobile {
      right: 0;
      transform: translateX(25%);
    }
  }

  &__team {
    max-width: 890px;
    width: 890px;
    position: absolute;
    top: 234px;
    left: -4px;
    z-index: 2;

    @include full-hd {
      left: 50px;
    }

    @include before-hd {
      top: 234px;
      left: -78px;
    }

    @include big-desktop {
      top: 234px;
    }

    @include desktop {
      max-width: 740px;
      top: 324px;
    }

    @include high-desktop {
      width: 650px;
      top: 378px;
      left: -90px;
    }

    @include middle-desktop {
      display: none;
    }
  }

  &__information {
    &-content {
      max-width: 430px;
      position: absolute;
      top: 0;
      z-index: 1;

      @include high-tablet {
        top: 80px;
      }

      @include mobile {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse;
        top: 0;
        left: 0;
        z-index: 1;
      }
    }
  }

  &__target-text {
    max-width: 312px;
    margin: 0;
    padding-bottom: 26px;
    font-weight: 500;
    font-size: 22px;
    line-height: 125%;
    color: var(--light-color);
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);

    @include mobile {
      max-width: 242px;
      font-size: 16px;
      padding-bottom: 20px;
      align-self: flex-start;
      color: var(--dark-color);
      text-shadow: none;
    }
  }

  &__slide-image {
    -webkit-filter: drop-shadow(0px 0px 5px #fff);
    filter: drop-shadow(0px 0px 5px #fff);
  }
}

.column-wrapper {
  display: flex;

  @include small-tablet {
    flex-direction: column;
  }
}

.discount {
  position: relative;
  margin-bottom: 20px;

  &__image {
    position: relative;
    max-width: 264px;

    @include min-mobile {
      max-width: 230px;
    }
  }

  &__link {
    max-width: 245px;
    position: absolute;
    top: 5px;
    left: 10px;
    font-weight: 500;
    font-size: 20px;
    line-height: 130%;
    color: var(--light-color);

    @include full-hd {
      top: 8px;
    }

    @include min-mobile {
      top: 6px;
      font-size: 18px;
    }
  }
}

.btn__subscribe {
  display: inline-block;
  font-weight: 400;
  font-size: 22px;
  margin-bottom: 100px;
  padding: 10px 20px;
  line-height: 150%;
  color: #ffffff;
  border-radius: 7px;
  background: var(--primary-gradient);

  @include high-tablet {
    margin-bottom: 20px;
  }

  @include small-tablet {
    display: none;
  }
}

.slide__image {
  max-width: 100%;
  width: 100%;
  position: absolute;
  top: -82px;
  z-index: 2;

  @include full-hd {
    max-width: 1920px;
    width: 1332px;
    top: -150px;
  }

  @include before-hd {
    width: 1200px;
    top: -110px;
    left: -72px;
  }

  @include big-desktop {
    width: 1034px;
  }

  @include desktop {
    width: 938px;
    top: -60px;
  }

  @include high-desktop {
    width: 780px;
    margin-bottom: 30px;
  }

  @include middle-desktop {
    width: 670px;
  }

  @include high-tablet {
    width: 435px;
  }

  @include tablet {
    position: relative;
    margin-bottom: 40px;
    top: -50px;
    left: -186px;
  }

  @include small-tablet {
    margin-bottom: 20px;
    top: 5px;
    left: 0;
  }

  @include mobile {
    width: 150%;
    top: 0;
    left: -42px;
  }

  @include main-mobile {
    width: 170%;
    left: -200px;
  }

  @include main-mobile {
    width: 170%;
    left: -200px;
  }

  @include min-mobile {
    max-width: 160%;
    left: -200px;
  }
}

.inf__image {
  display: flex;
  width: 850px;
  max-width: 850px;
}

.grad__image {
  position: absolute;
  top: -6px;
  left: 0;
  height: 328px;
}

.bottom-wrapper {
  position: relative;
  height: 244px;

  @include tablet {
    height: inherit;
  }

  @include mobile {
    position: absolute;
    max-width: 100%;
    height: auto;
    left: 50%;
    width: 314px;
    bottom: -418px;
    padding: 20px;
    background: var(--light-color);
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7.68px;
    transform: translateX(-50%);
    z-index: 1;
  }

  @include main-mobile {
    position: absolute;
    width: 300px;
    bottom: -410px;
    left: 50%;
    transform: translateX(-50%);
  }

  @include min-mobile {
    padding: 20px 10px;
  }
}

.bottom__image-wrapper {
  position: absolute;
  top: -72px;
  left: -70px;
  overflow: hidden;
  z-index: 0;

  @include middle-desktop {
    position: relative;
  }

  @include high-tablet {
    top: 0;
  }

  @include mobile {
    display: none;
  }
}

.app {
  max-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 37px;

  &__link:not(:last-child) {
    margin-right: 20px;

    @include mobile {
      margin-right: 15px;
    }
  }

  @include mobile {
    margin: 0;
    order: -1;
  }
}

.address {
  font-style: normal;

  &__text {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: var(--light-color);

    @include small-tablet {
      max-width: 360px;
    }

    @include mobile {
      padding-bottom: 20px;
      color: var(--dark-color);
    }
  }

  &__link {
    color: var(--light-color);
  }

  &__phone {
    font-weight: 700;
  }
}

.mask {
  -webkit-mask-image: url("../img/sprite.svg#mask");
  mask-image: url("../img/sprite.svg#mask");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 70%;
  mask-size: 70%;

  @include before-hd {
    -webkit-mask-size: 72%;
    mask-size: 72%;
  }
}

.mob__subscribe-message {
  display: none;

  @include small-tablet {
    display: block;
    max-width: 434px;
    margin: 0;
    padding-bottom: 22px;
    font-weight: 700;
    font-size: 22px;
    line-height: 125%;
    color: var(--dark-color);
  }
}

.btn-mob__subscribe {
  display: none;

  @include small-tablet {
    display: inline-block;
    font-weight: 400;
    font-size: 22px;
    margin-bottom: 20px;
    padding: 10px 20px;
    line-height: 150%;
    color: #ffffff;
    border-radius: 7px;
    background: var(--primary-gradient);
    align-self: flex-start;
  }

  @include tablet {
    margin-bottom: 20px;
  }
}

.bottom__bg-mobile {
  display: none;

  @include mobile {
    position: relative;
    display: block;
    min-height: 350px;
    margin-top: 100px;
    background-image: url("../../img/mobile-bottom-bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 100%;
  }

  @include main-mobile {
    margin-left: 10px;
    margin-right: 10px;
    min-height: 360px;
    margin-top: 80px;
    background-size: 100% 100%;
    background-position: center;
  }

  @include min-mobile {
    min-height: 348px;
    margin-left: 5px;
    margin-right: 5px;
  }
}

.bg-mobile__image {
  display: none;

  @include mobile {
    display: block;
    position: absolute;
    bottom: 254px;
    left: 50%;
    transform: translateX(-50%);
  }

  @include main-mobile {
    max-width: 300px;
    width: 100%;
    height: 187px;
    left: 50%;
    bottom: 242px;
    margin-left: 0;
    object-fit: contain;
    transform: translate(-50%, 0);
  }

  @include min-mobile {
    bottom: 232px;
  }
}

.hero__slider {
  width: 100%;
  overflow: initial;
}
