/* stylelint-disable */

@mixin for-desktop {
  @media (min-width: (1025px)) {
    @content;
  }
}

@mixin full-hd {
  @media (max-width: (1920px)) {
    @content;
  }
}

@mixin before-hd {
  @media (max-width: (1691px)) {
    @content;
  }
}

@mixin big-desktop {
  @media (max-width: (1440px)) {
    @content;
  }
}

@mixin desktop {
  @media (max-width: (1362px)) {
    @content;
  }
}

@mixin high-desktop {
  @media (max-width: (1220px)) {
    @content;
  }
}

@mixin middle-desktop {
  @media (max-width: (1125px)) {
    @content;
  }
}

@mixin high-tablet {
  @media (max-width: (1024px)) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: (847px)) {
    @content;
  }
}

@mixin small-tablet {
  @media (max-width: (768px)) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: (576px)) {
    @content;
  }
}

@mixin main-mobile {
  @media (max-width: (390px)) {
    @content;
  }
}

@mixin min-mobile {
  @media (max-width: (358px)) {
    @content;
  }
}
